<template>
  <div style="width: 100%; min-height: 100vh; background: white;">
    <!-- 顶部导航栏 -->
    <van-nav-bar
      title="个人资料"
      left-arrow
      @click-left="$router.back()"
      style="position: fixed; top: 0; left: 0; width: 100%; background-color: #000;"
    ></van-nav-bar>

    <!-- 空白间隙 -->
    <div style="height: 46px;"></div>

    <!-- 顶部提示 -->
    <div class="tops">
      <img src="/static/image/safety.d3a323b5ad7cca95958707791f3861b1.png" alt="">
      <div class="tes">完善账户信息，更安全</div>
    </div>

    <!-- 内容区域 -->
    <div class="boxst">
      <!-- 完善个人资料 -->
      <div class="hgs" @click="$parent.goNav('/userInfo')">
        <div class="lfs">
          <div class="topas">完善个人资料</div>
          <div class="tisg">资料更完整，我们的服务更加周到</div>
        </div>
        <div class="rigs">
          去完善
          <img src="/static/image/right.b9a9c7c64558347505384ad01922580c.png" alt="">
        </div>
      </div>

      <!-- 卡片管理 -->
      <div class="hgs" @click="$parent.goNav('/wallet')">
        <div class="lfs">
          <div class="topas">卡片管理</div>
          <div class="tisg">如需提现，请绑定银行卡或虚拟币地址</div>
        </div>
        <div class="rigs">
          <img src="/static/image/right.b9a9c7c64558347505384ad01922580c.png" alt="">
        </div>
      </div>

      <!-- 登录密码管理 -->
      <div class="hgs" @click="$parent.goNav('/password?type=1')">
        <div class="lfs">
          <div class="topas">登录密码管理</div>
          <div class="tisg">定期修改登录密码，有利账户安全</div>
        </div>
        <div class="rigs">
          <img src="/static/image/right.b9a9c7c64558347505384ad01922580c.png" alt="">
        </div>
      </div>

      <!-- 取款密码管理 -->
      <div class="hgs" @click="$parent.goNav('/password?type=2')">
        <div class="lfs">
          <div class="topas">取款密码管理</div>
          <div class="tisg">定期修改登录密码，有利账户安全</div>
        </div>
        <div class="rigs">
          <img src="/static/image/right.b9a9c7c64558347505384ad01922580c.png" alt="">
        </div>
      </div>

      <!-- 取款密码管理 -->
      <div class="hgs" @click="userInfo.kycAuth != 1 && userInfo.kycAuth != 2 && $parent.goNav('/kycAuth')">
        <div class="lfs">
          <div class="topas">KYC认证</div>
          <span v-if="!userInfo.kycAuth || userInfo.kycAuth == 0" class="tisg">认证个人信息，提交身份证明。确保您的账户安全</span>
          <span v-if="userInfo.kycAuth == 1" class="tisg">您的认证审批中，请稍后重新查看</span>
          <span v-if="userInfo.kycAuth == 3" class="tisg">您的认证未成功，请重新提交资料</span>
          <span v-if="userInfo.kycAuth == 2" class="tisg">您已通过KYC认证，您的账户将受到更好的保护</span>
        </div>
        <div class="rigs">
          <img src="/static/image/right.b9a9c7c64558347505384ad01922580c.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCenter',
  data() {
    return {
      userInfo: null
    }
  },
  created() {
    const that = this
    that.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const that = this
      that.userInfo = await that.$parent.getUserInfo()
      console.log('用户信息为', that.userInfo);
    }
  },
}
</script>

<style scoped>
.tops {
  display: flex;
  align-items: center;
  padding: 10px;
}
.tes {
  font-size: 14px;
  color: #333;
}
.boxst {
  padding-top: 10px;
}
.hgs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}
.lfs {
  display: flex;
  flex-direction: column;
}
.topas {
  font-size: 16px;
  color: #000;
}
.tisg {
  font-size: 12px;
  color: #888;
}
.rigs {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.rigs img {
  margin-left: 5px;
}
</style>
