<template>
    <div style="background: white; min-height: 100vh">
        <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #000" title="VIP明细"
            left-arrow @click-left="$parent.goNav('/vip')">
        </van-nav-bar>
        <div style="height: 46px"></div>

        <div class="option-content">
            <div @click="changeType(1)" :class="currentType == 1 ? 'option-active' : ''" class="option-item">每周红包</div>
            <div @click="changeType(2)" :class="currentType == 2 ? 'option-active' : ''" class="option-item">返水比例</div>
            <div @click="changeType(3)" :class="currentType == 3 ? 'option-active' : ''" class="option-item">提款额度</div>
        </div>

        <div class="type-detail-content">
            <div class="type-title">
                VIP{{ typeNameMap[currentType] }}
            </div>
            <div v-if="currentType == 1"
                style="width: 100%; margin-top: 10px; display: flex; justify-content: center; flex-direction: column;">
                <div class="type-list-content">
                    <div class="list-text">VIP等级</div>
                    <div class="list-text">有效投注要求</div>
                    <div class="list-text">每周红包</div>
                </div>

                <div class="type-list-content" :class="item.vipLevel == (userInfo.vipLevel || 0) ? 'active-list' : ''"
                    v-for="(item, index) in getWeeklyRed" :key="index">
                    <div class="list-text">VIP{{ item.vipLevel }}</div>
                    <div class="list-text">{{ item.redEnvelopeNeedWager }}</div>
                    <div class="list-text">{{ item.redEnvelope }}</div>
                </div>

            </div>

            <div v-if="currentType == 2"
                style="width: 100%; margin-top: 10px; display: flex; justify-content: center; flex-direction: column;">
                <div class="type-list-content">
                    <div class="list-text">VIP等级</div>
                    <div class="list-text">真人</div>
                    <div class="list-text">电子</div>
                    <div class="list-text">棋牌</div>
                    <div class="list-text">体育</div>
                    <div class="list-text">彩票</div>
                    <div class="list-text">电竞</div>
                    <div class="list-text">捕鱼</div>
                </div>

                <div class="type-list-content" :class="item.vipLevel == (userInfo.vipLevel || 0) ? 'active-list' : ''"
                    v-for="(item, index) in getGameType" :key="index">
                    <div class="list-text">VIP{{ item.vipLevel }}</div>
                    <div class="list-text">{{ item.liveCasino }}</div>
                    <div class="list-text">{{ item.slotsGame }}</div>
                    <div class="list-text">{{ item.chessGame }}</div>
                    <div class="list-text">{{ item.sport }}</div>
                    <div class="list-text">{{ item.lottery }}</div>
                    <div class="list-text">{{ item.eSport }}</div>
                    <div class="list-text">{{ item.fishing }}</div>
                </div>

            </div>

            <div v-if="currentType == 3"
                style="width: 100%; margin-top: 10px; display: flex; justify-content: center; flex-direction: column;">
                <div class="type-list-content">
                    <div class="list-text">VIP等级</div>
                    <div class="list-text">每日提款次数</div>
                    <div class="list-text">每日提款额度 </div>

                </div>

                <div class="type-list-content" :class="item.vipLevel == (userInfo.vipLevel || 0) ? 'active-list' : ''"
                    v-for="(item, index) in getWithdraw" :key="index">
                    <div class="list-text">VIP{{ item.vipLevel }}</div>
                    <div class="list-text">{{ item.dailyWithdrawTimes }}</div>
                    <div class="list-text">{{ item.dailyWithdrawAmount }}</div>

                </div>

            </div>
            <div style="font-size: .36rem;margin: 10px 10px;">
                注：以上为自研场馆最高返水比例，具体返水金额请以实际游戏为准，其它疑问请联系7*24在线客服咨询。
            </div>
            <div style="display: flex; align-items: center; width: 100%; justify-content: center; ">
                <div class="myCenterCommon__userContent__2fHEF  vipDetail__detailWrap__shgf_">
                    <div class=" type-title">活动的一般条款与规则</div>
                    <div class="vipDetail__ruleGroup__sadx2">
                        <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">1</span>
                            <div class="vipDetail__text__hg8WK">晋升标准：累计存款和投注额达到相应级别的要求，即可在次日24点前晋级相应VIP等级</div>
                        </div>
                        <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">2</span>
                            <div class="vipDetail__text__hg8WK">晋升顺序：VVIP等级达到相应的要求可每天晋升一级，但VIP等级不可越级晋升</div>
                        </div>
                        <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">3</span>
                            <div class="vipDetail__text__hg8WK">
                                保级要求：会员在达到某VIP等级后，90天内投注需要完成保级要求。如果在此期间完成晋升，保级要求重新按照当前等级计算
                            </div>
                        </div>
                        <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">4</span>
                            <div class="vipDetail__text__hg8WK">
                                降级标准：如果会员在1个季度（90天计算）内没有完成相应的保级要求流水，系统会自动降级1个等级，相应的返水及其它优惠也会随之调整至降级后的等级
                            </div>
                        </div>
                        <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">5</span>
                            <div class="vipDetail__text__hg8WK">
                                升级礼金：升级礼金在会员达到该会员级别后可在福利中心页面进行手动领取，每个级别的升级礼金每位会员仅能获得1次（升级礼金1倍流水即可提款）</div>
                        </div>
                        <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">6</span>
                            <div class="vipDetail__text__hg8WK">
                                生日礼金：会员在注册90天内过生日，今年将不能领取生日礼金。另注册时间大于90天的会员需在生日当天的VIP页面进行自助领取，每年可领取一次（生日彩金1倍流水即可提款）
                            </div>
                        </div>
                        <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">7</span>
                            <div class="vipDetail__text__hg8WK">
                                每周红包：会员在上一周（周六0点至周五23点59分59秒）达到会员VIP等级（以周五23:59的VIP等级为准）对应的有效投注要求，即可在每周六的13点后领取每周红包，每周三24点后红包将会过期，请及时领取。红包仅支持转入场馆钱包，满足对应场馆流水要求即可解锁。各等级红包金额和领取要求详见"VIP详情-每周红包规则表"
                            </div>
                        </div>
                        <!-- <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">8</span>
            <div class="vipDetail__text__hg8WK">晋级优惠：可在VIP页面自助领取，领取晋级优惠活动后开始计算活动有效流水；此优惠不与平台其他优惠共享，</div>
          </div>
          <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">9</span>
            <div class="vipDetail__text__hg8WK">VIP晋级：每个等级的VIP晋级优惠仅支持指定场馆申请（只可申请符合当前等级的晋级优惠）</div>
          </div> -->
                        <div class="vipDetail__ruleItem__m0ITw"><span class="vipDetail__index__sCXYd">8</span>
                            <div class="vipDetail__text__hg8WK">
                                VIP返水：VIP返水优惠发放金额根据会员当天北京时间00:00—23:59之间的有效投注进行计算，当天所有的投注额返水将在注单结算当天结束后24小时内发放到福利中心，会员可在福利中心页面进行手动领取（VIP返水1倍流水即可提款）
                            </div>
                        </div>
                        <div class="vipDetail__ruleMark__E9xjB">
                            <div class="vipDetail__mark__oJQCN">平台保留对活动的修改、停止及最终解释权。</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div style="width: 100%; margin: 20px 0px; display: flex; justify-content: center; align-items: center;">
            <div style="background-color: white; padding: 3px 10px; border-radius: 16px;">
                <a href="#" style="color:#E4CA99;font-size: .52rem;">回到顶部</a>
            </div>
            
        </div>
    </div>


</template>

<script>
export default {
    name: 'vipDetail',
    data() {
        return {
            currentType: 1,
            typeNameMap: {
                1: '每周红包',
                2: '返水比例',
                3: '提款额度'
            },
            userInfo: this.$store.state.userInfo,
            vipLis: [],
            allVipLis: [],
            selectedVipInfo: {},
        }
    },
    computed: {
        getAmount() {
            const that = this;
            const allAmount = {
                needRecharge: formatNumberQfw(that.selectedVipInfo.needRecharge || 0, 2),
                needWager: formatNumberQfw(that.selectedVipInfo.needWager || 0, 2),
                relegationWager: formatNumberQfw(that.selectedVipInfo.relegationWager || 0, 2),
                needRechargePercentage: that.userInfo.curRecharge / that.selectedVipInfo.needRecharge * 100,
                needWagerPercentage: that.userInfo.curWagered / that.selectedVipInfo.needWager * 100,
                relegationWagerPercentage: that.userInfo.relegationWagered / that.selectedVipInfo.relegationWager * 100,
                dailyWithdrawAmount: formatNumberQfw(that.selectedVipInfo.dailyWithdrawAmount, 0) || 0,
                reacharge: formatNumberQfw(that.selectedVipInfo.needRecharge || 0, 0, 0),
                wager: formatNumberQfw(that.selectedVipInfo.needWager || 0, 0, 0),
                relegationWagered: formatNumberQfw(that.selectedVipInfo.relegationWager || 0, 0, 0),
            }
            return allAmount;
        },
        getGameType() {
            const that = this;
            const datas = []
            that.allVipLis.forEach((item) => {
                datas.push({
                    vipLevel: item.vipLevel,
                    liveCasino: (item.liveCasino * 100).toFixed(2),
                    slotsGame: (item.slotsGame * 100).toFixed(2),
                    chessGame: (item.chessGame * 100).toFixed(2),
                    sport: (item.sport * 100).toFixed(2),
                    lottery: (item.lottery * 100).toFixed(2),
                    eSport: (item.eSport * 100).toFixed(2),
                    fishing: (item.fishing * 100).toFixed(2),
                });
            })
            return datas;
        },

        getWithdraw() {
            const that = this;
            const datas = []
            that.allVipLis.forEach((item) => {

                datas.push({
                    vipLevel: item.vipLevel,
                    dailyWithdrawTimes: item.dailyWithdrawTimes,
                    dailyWithdrawAmount: item.dailyWithdrawAmount,
                });
            })
            return datas;
        },
        getWeeklyRed() {
            const that = this;
            const datas = []
            that.allVipLis.forEach((item) => {
                datas.push({
                    vipLevel: item.vipLevel,
                    redEnvelope: item.redEnvelope,
                    redEnvelopeNeedWager: item.redEnvelopeNeedWager,
                });
            })

            console.log('获取到每周红包信息', datas);

            return datas;
        }
    },
    created() {
        let that = this;
        // that.uservip();
        that.getConfitData();
    },
    methods: {
        changeType(type) {
            this.currentType = type
        },
        getConfitData() {
            let that = this;
            that.configData = that.$parent.getConfigDatas();
            if (!that.configData || !that.configData.vipClubList) {
                setTimeout(() => {
                    that.getConfitData();
                }, 500);
            } else {
                that.vipLis = that.configData.vipClubList.filter((item) => item.vipLevel > 0);
                that.allVipLis = that.configData.vipClubList;
                console.log('获取到配置信息为---vip信息', that.vipLis, that.userInfo);
                if (that.vipLis && that.vipLis.length > 0) {
                    that.selectedVipInfo = that.vipLis.find((item) => item.vipLevel == (that.userInfo.vipLevel || 1));
                    that.vipTab = that.userInfo.vipLevel || 0;
                    that.step = 100 / (that.vipLis.length - 1)
                    console.log('选中的VIP等级', that.selectedVipInfo);
                }
            }
        },
    }
}
</script>

<style lang="less" scoped>
.option-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;


    .option-item {
        font-size: .46rem;
        line-height: 1.06rem;
    }

    .option-active {
        border-bottom: 1px solid #BD171B;
    }

}

.type-detail-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;


    .type-title {
        width: 100%;
        margin-top: 10px;
        margin-left: 30px;
        font-size: .50rem;
        font-weight: 500;
        position: relative;
    }

    .type-title::before {
        content: "";
        position: absolute;
        top: 50%;
        translate: -50% -50%;
        left: -10px;
        width: 4px;
        height: 60%;
        background-image: linear-gradient(to right, #f49c9d, #de373ab5);
    }
}

.type-list-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;

    .list-text {
        font-size: .32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 12px 10px;
    }
}

.type-list-content:nth-child(odd) {
    background-color: #EBEFF7;
}

.active-list {
    background-color: #E4CA99 !important;
}


.myCenterCommon__userContent__2fHEF {

    //   padding: 20px !important;
    min-height: unset !important;
    width: 98% !important;
}

.before_page_text {
    cursor: pointer;
}

.before_page_text:hover {
    opacity: 0.6;
}

.swiper-slide {
    background-image: url(/static/image/viptq/vip_card_tag_big_nor.a1b1a7.webp);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    height: 204px;
    position: relative;
    width: 448px;
}

.vipDetail__badgeIcon__znE_X {
    height: 116px;
    position: absolute;
    right: 30px;
    top: 0;
    width: 116px;
}

.vipDetail__level__Xcum3 {
    color: #fff;
    font-family: DINPro !important;
    font-size: 36px;
    line-height: 28px;
    margin: 47px 0 50px 42px;
}

.vipDetail__cardInfoGroup__gKw0l {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
}

.vipDetail__withDeposit___CJ6G {
    padding: 0 40px;
}

.vipDetail__cardInfoGroup__gKw0l .vipDetail__cardInfoItem__rm3HA {
    text-align: center;
}

.vipDetail__cardInfoGroup__gKw0l .vipDetail__cardInfoItem__rm3HA .vipDetail__value__SIWk7 {
    color: #fff;
    font-family: Din !important;
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 5px;
}

.vipDetail__cardInfoGroup__gKw0l .vipDetail__cardInfoItem__rm3HA .vipDetail__text__hg8WK {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    opacity: .6;
}

.vipDetail__tableWrap__ifEXT {
    box-sizing: border-box;
    margin-bottom: 16px;
    padding-top: 13px;
    width: 100%;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ {
    border-radius: 4px;
    margin-top: 16px;
    overflow: hidden;
    width: 100%;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N {
    align-items: center;
    background: #ebeff7;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N .vipDetail__th__ggOID:first-child {
    min-width: 84px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N .vipDetail__th__ggOID {
    color: #1d1d1f;
    font-family: DINPro-Medium !important;
    font-size: 14px;
    font-weight: 500;
    height: 54px;
    line-height: 54px;
    text-align: center;
    width: 74px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N .vipDetail__th__ggOID.vipDetail__vipGrade__H3szF {
    background: #e4ca99;
    position: relative;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N .vipDetail__th__ggOID.vipDetail__vipGrade__H3szF img {
    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN {
    scrollbar-width: none;
    -ms-overflow-style: none;
    /* IE、Edge */
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}


.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N:nth-child(odd) {
    background: rgba(235, 239, 247, .2);
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N .vipDetail__td__Ru8_Z:first-child {
    min-width: 84px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N .vipDetail__td__Ru8_Z {
    color: #1d1d1f;
    font-family: DINPro !important;
    font-size: 14px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    vertical-align: initial;
    width: 74px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N .vipDetail__td__Ru8_Z.vipDetail__activeTd__Vb4fi {
    background: #e4ca99;
    color: #fff;
    font-family: DINPro-Medium !important;
    font-weight: 500;
}

.user-box:last-child {
    flex: 1 1;
}

.user-box {
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    overflow: visible;
    padding: 20px;
    box-shadow: 0 4px 16px rgba(152, 161, 182, .04);
}

.user-box,
.user-box-h3 .money-main {
    width: 100%;
}

.user-box-title {
    font-family: PingFangSC-Medium;
    font-weight: 700;
    color: #30383f;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.detail-main ul {
    margin-top: 26px;
}

.detail-main li {
    width: 100%;
    height: auto;
    margin-bottom: 18px;
    counter-increment: my-awesome-counter;
    padding-left: 24px;
    position: relative;
    line-height: 22px;
    font-size: 14px;
    color: #48535e;
}

.detail-main li:before {
    content: counter(my-awesome-counter);
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: #e4ca99;
    position: absolute;
    top: 3px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vipDetail__detailWrap__shgf_ .vipDetail__sectionTitle__ulPYk {
    color: #1d1d1f;
    font-family: DinProBold, MicrosoftYaHei, PingFang SC !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.vipDetail__detailWrap__shgf_ .vipDetail__subSectionTitle__LMuiK {
    display: flex;
    font-size: 16px;
}

.vipDetail__ruleGroup__sadx2 {
    margin-top: 16px;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleItem__m0ITw {
    box-sizing: border-box;
    margin-bottom: 24px;
    padding-left: 24px;
    position: relative;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleItem__m0ITw .vipDetail__index__sCXYd {
    background: #e4ca99;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    height: 16px;
    left: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 3px;
    width: 16px;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleItem__m0ITw .vipDetail__text__hg8WK {
    color: #1d1d1f;
    font-size: 14px;
    line-height: 22px;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleMark__E9xjB {
    margin-top: 16px;
    padding-left: 24px;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleMark__E9xjB .vipDetail__mark__oJQCN {
    color: #bd171b;
    font-size: 14px;
    line-height: 22px;
}

.swiper-button-next {
    right: 10px;
}

.swiper-button-prev {
    left: 10px;
}
</style>