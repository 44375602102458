<template>
    <div style="width: 100%; min-height: 100vh; background: white;">
        <!-- 顶部导航栏 -->
        <van-nav-bar title="KYC认证" left-arrow @click-left="$router.back()"
            style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd;"></van-nav-bar>
        <div style="height: 46px;"></div>
        <div class="usrse" v-if="step === 1">
            <div class="hgs">
                <div class="nams">真实姓名</div>
                <div style="border-bottom: 1px solid #f2f2f2">
                    <van-cell-group>
                        <van-field v-model="tankuangUserName" placeholder="请输入真实姓名" />
                    </van-cell-group>
                </div>
            </div>

            <div class="hgs">
                <div class="nams">证件类型</div>
                <div style="border-bottom: 1px solid #f2f2f2; margin: 10px 0px;height: 40px;">
                    <van-radio-group v-model="tankuangCidRadio" direction="horizontal">
                        <van-radio checked-color="#bd171b" name="1" style="font-size: .43rem; ">身份证</van-radio>
                        <van-radio checked-color="#bd171b" name="2" style="font-size: .43rem; ">护照</van-radio>
                    </van-radio-group>
                </div>
            </div>

            <div class="hgs">
                <div class="nams">证件号码</div>
                <div style="border-bottom: 1px solid #f2f2f2">
                    <van-cell-group>
                        <van-field v-model="tankuangCid" placeholder="请输入证件号码" />
                    </van-cell-group>
                </div>
            </div>

            <button @click="checkKycStepOne" class="van-button van-button--info van-button--normal"
                style="margin-top: 20px; width: 100%;">
                <div class="van-button__content">
                    <span data-v-3fd1c12b="" class="van-button__text">Next</span>
                </div>
            </button>
        </div>

        <div v-if="step === 2">
            <div class="identityContent">
                <div class="demoContent">

                </div>
                <div class="buttonContent">
                    <div class="sc_button_content">
                        <div style="width: 50%;display: flex; flex-direction: column; align-items: center;">
                            <img v-if="faceUrl" :src="faceUrl" class="avatar">
                            <van-uploader accept="image/jpeg', 'image/pjpeg', 'image/png', 'image/jpg"
                                :after-read="afterReadFace">
                                <button class="van-button van-button--info van-button--normal"
                                    style="margin-top: 20px; width: 100%;">
                                    <div class="van-button__content">
                                        <span data-v-3fd1c12b="" class="van-button__text">上传正面</span>
                                    </div>
                                </button>
                            </van-uploader>
                        </div>

                        <div style="width: 50%;display: flex; flex-direction: column; align-items: center;">
                            <img v-if="backUrl" :src="backUrl" class="avatar">
                            <van-uploader accept="image/jpeg', 'image/pjpeg', 'image/png', 'image/jpg"
                                :after-read="afterReadBack">
                                <button class="van-button van-button--info van-button--normal"
                                    style="margin-top: 20px; width: 100%;">
                                    <div class="van-button__content">
                                        <span data-v-3fd1c12b="" class="van-button__text">上传背面</span>
                                    </div>
                                </button>
                            </van-uploader>
                        </div>


                    </div>
                    <div class="sc_button_content">
                        <button @click="step = 1" class="van-button van-button--info van-button--normal"
                            style="margin-top: 20px; width: 40%;">
                            <div class="van-button__content">
                                <span data-v-3fd1c12b="" class="van-button__text">前一页</span>
                            </div>
                        </button>
                        <button @click="checkKycStepTwo" class="van-button van-button--info van-button--normal"
                            style="margin-top: 20px; width: 40%;">
                            <div class="van-button__content">
                                <span data-v-3fd1c12b="" class="van-button__text">下一页</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="step === 3">
            <div class="identityContent">
                <div>
                    允许访问摄像头
                </div>
                <div style="width: 90%;border: 1px solid red; padding: 5px 10px; margin: 10px 0;">
                    要继续验证，我们需要访问您设备的相机。
                    请点击左上角窗口中的“允许”按钮，授权浏览器使用您的相机。
                </div>
                <div class="buttonContent">
                    <div
                        style="margin: 0 auto; display: flex; flex-direction: column; row-gap: 3px; align-items: center;">
                        <img v-if="cameraPhoto" :src="cameraPhoto" class="avatar">
                        <button @click="step = 4" class="van-button van-button--info van-button--normal"
                            style="margin-top: 20px; width: 100%;">
                            <div class="van-button__content">
                                <span data-v-3fd1c12b="" class="van-button__text">继续</span>
                            </div>
                        </button>
                        <span style="font-size: .42rem;">或者</span>
                        <van-uploader accept="image/jpeg', 'image/pjpeg', 'image/png', 'image/jpg"
                            :after-read="afterReadPhoto">
                            <button class="van-button van-button--info van-button--normal"
                                style="margin-top: 20px; width: 100%;">
                                <div class="van-button__content">
                                    <span data-v-3fd1c12b="" class="van-button__text">上传文件</span>
                                </div>
                            </button>
                        </van-uploader>
                    </div>
                    <div class="sc_button_content">
                        <button @click="step = 2" class="van-button van-button--info van-button--normal"
                            style="margin-top: 20px; width: 40%;">
                            <div class="van-button__content">
                                <span data-v-3fd1c12b="" class="van-button__text">前一页</span>
                            </div>
                        </button>
                        <button @click="handleSubmit" class="van-button van-button--info van-button--normal"
                            style="margin-top: 20px; width: 40%;">
                            <div class="van-button__content">
                                <span data-v-3fd1c12b="" class="van-button__text">确定</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="step === 4">
            <div style="display: block;top: -8% !important;">
                <div style="width: 100%; display: flex; align-items: center; flex-direction: column;">
                    <div style="width: 100%;display: flex; align-items: center; justify-content: center;">
                        <span style="display: block; margin: 5px auto;">
                            拍摄照片</span><span class="msgBox__close_icon__3J9a5" @click="stopCamera"></span>
                    </div>
                    <div
                        style="display: flex; justify-content: center; align-items: center; width: 100%; padding: 10px 0px;">
                        <video ref="video" autoplay style="height: 200px"></video>
                    </div>

                    <!-- <div style="width: 100%; display: flex; justify-content: center; align-items: center; ">
                        <button class="msgBox__confirm__3ypnx" style="margin: 5px auto;" @click="takePhoto">拍照</button>
                    </div> -->
                    <button @click="takePhoto" class="van-button van-button--info van-button--normal"
                        style="margin-top: 20px; width: 40%;">
                        <div class="van-button__content">
                            <span data-v-3fd1c12b="" class="van-button__text">拍照</span>
                        </div>
                    </button>
                    <canvas ref="canvas" style="display: none"></canvas>
                    <div
                        style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 10px 0px;">
                        <img :src="cameraPhoto" alt="Captured Photo" v-if="cameraPhoto" style="height: 250px;" />
                        <button v-if="cameraPhoto" @click="handleCamraUpLoad"
                            class="van-button van-button--info van-button--normal"
                            style="margin-top: 20px; width: 40%;">
                            <div class="van-button__content">
                                <span data-v-3fd1c12b="" class="van-button__text">确定</span>
                            </div>
                        </button>
                    </div>

                </div>
            </div>
        </div>

    </div>


</template>

<script>
import { applyKycAuth } from '@/service/hall';
import axios from 'axios';
export default {
    name: "kycAuthpage",
    data() {
        return {
            tankuangCid: null,
            tankuangUserName: this.$store.state.userInfo.realName,
            tankuangCidRadio: "1",
            step: 1,
            accpetFileTypes: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/jpg'],
            faceUrl: null,
            backUrl: null,
            cameraPhoto: null,
            front: null,  //正面照片id
            back: null,  //背面照片id
            photo: null,  //照片id
            openCamera: false,
            stream: null, // 媒体流
            file: null, // 上传的文件
            cameraFile: null, // 上传的摄像头文件
        }
    },
    watch: {
        step(val) {
            console.log("当前步骤:", val);
            if (val === 4) {
                this.startCamera();
            } else {
                this.stopCamera();
            }
        }
    },
    methods: {

        async handleCamraUpLoad() {
            const that = this;
            if (!that.cameraFile) {
                console.error("没有文件可上传");
                return;
            }
            const res = await that.handleUpLoad(that.cameraFile)
            console.log('carmeraUpLoad--上传成功', res);
            that.cameraPhoto = res.data.url;
            that.photo = res.data.id;   
            console.log('handleSubmit--上传成功', that.photo);
                     
            that.step = 3;
        },
        stopCamera() {
            console.log("关闭摄像头", this.stream);

            if (this.stream) {
                // 停止所有媒体轨道
                this.stream.getTracks().forEach(track => {
                    console.log("停止轨道", track);
                    if (track.readyState === 'live') {
                        track.stop();
                    }
                    console.log("轨道状态", track.readyState);
                });

                navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
                    stream.getTracks().forEach(track => track.stop());
                });

                this.stream = null;
                this.$refs.video.pause();
                this.$refs.video.srcObject = null;
                this.$refs.video.load();
                console.log("摄像头已关闭");

                // 可选：检查设备状态
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    console.log("设备列表:", devices);
                    const cameras = devices.filter(device => device.kind === 'videoinput');
                    console.log("摄像头状态:", cameras);
                });
            } else {
                console.warn("没有摄像头流可关闭");
            }
        },
        async startCamera() {
            const that = this;
            try {
                console.log("开始打开摄像头");

                // 确保 DOM 完全渲染后再访问 $refs
                await that.$nextTick();

                if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                    console.error("浏览器不支持 getUserMedia API 或者需要在 HTTPS 环境下使用");
                    that.$parent.showTost(0, "浏览器不支持 getUserMedia API 或者需要在 HTTPS 环境下使用");
                    return "浏览器不支持 getUserMedia API 或者需要在 HTTPS 环境下使用";
                }

                let stream = await navigator.mediaDevices.getUserMedia({ video: true });

                // 确保 video 元素被正确引用
                if (that.$refs.video) {
                    that.$refs.video.srcObject = stream;
                    that.stream = stream;
                } else {
                    console.error("无法找到 video 元素的引用");
                }

                return null;
            } catch (error) {
                let message = "无法访问摄像头。";

                if (error.name === 'NotAllowedError') {
                    message = "用户拒绝访问摄像头。";
                    console.error("用户拒绝访问摄像头。");
                } else if (error.name === 'NotFoundError') {
                    message = "未找到摄像头设备。";
                    console.error("未找到摄像头设备。");
                } else {
                    message = "无法访问摄像头: " + error.message;
                    console.error("无法访问摄像头: ", error);
                }
                that.$parent.showTost(0, message);
                return message;
            }
        },
        takePhoto() {
            const that = this;
            let video = that.$refs.video;
            let canvas = that.$refs.canvas;

            if (!video || !canvas) {
                console.error("无法找到 video 或 canvas 元素的引用");
                return;
            }

            // 设置 canvas 尺寸和视频一致
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // 将视频内容绘制到 canvas 上
            let context = canvas.getContext("2d");
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // 获取拍摄的照片（base64 格式）
            that.cameraPhoto = canvas.toDataURL("image/png");

            // 转换为文件并上传
            that.cameraFile = that.dataURLtoFile(that.cameraPhoto, 'photo.png');
            console.log("拍摄的照片文件:", that.cameraFile);
        },
        dataURLtoFile(dataURL, filename) {
            let arr = dataURL.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        async handleUpLoad(file) {
            const that = this;
            that.$parent.showLoading(); // 显示加载动画
            const formData = new FormData();
            formData.append('file', file); // 添加文件到 FormData
            try {
                const response = await axios.post('https://manage.ms9708.com//gateway/kyc/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log("上传成功:", response.data);
                return response.data;
            } catch (error) {
                console.error("上传失败:", error);
                that.$parent.showTost(0, '上传失败');
            } finally {
                that.$parent.hideLoading(); // 隐藏加载动画
            }
        },
        async afterReadFace(file) {
            const that = this;
            console.log('afterRead', file);
            const res = await that.handleUpLoad(file.file)
            console.log('afterRead--上传成功', res);
            that.faceUrl = res.data.url;
            that.front = res.data.id;
        },

        async afterReadBack(file) {
            const that = this;
            console.log('afterRead', file);
            const res = await that.handleUpLoad(file.file)
            console.log('afterRead--上传成功', res);
            that.backUrl = res.data.url;
            this.back = res.data.id;
        },

        async afterReadPhoto(file) {
            const that = this;
            console.log('afterRead', file);
            const res = await that.handleUpLoad(file.file)
            console.log('afterRead--上传成功', res);
            this.cameraPhoto = res.data.url;
            this.photo = res.data.id;
        },

        closeCamera() {
            this.openCamera = false;
        },
        async handleSubmit() {
            const that = this;
            console.log('handleSubmit', that.photo);
            
            if (!that.front) {
                that.$parent.showTost(0, '请上传正面照片');
                return;
            }
            if (!that.back) {
                that.$parent.showTost(0, '请上传背面照片');
                return;
            }
            if (!that.photo) {
                that.$parent.showTost(0, '请拍摄或上传照片');
                return;
            }
            const res = await applyKycAuth({
                msgID: 400039,
                name: that.tankuangUserName,                   //名字
                documentType: Number(that.tankuangCidRadio),                 //证件类型 1.身份证 2.护照
                IDNumber: that.tankuangCid,                     //证件号码
                front: that.front.toString(),                       //正面
                back: that.back.toString(),             //背面
                photo: that.photo.toString(),                      //照片
            })
            console.log('提交kyc返回值', res);

            if (res.error > 0) {
                that.$parent.showTost(0, res.error);
                return
            }
            that.$router.push({ path: '/userCent' });
        },
        async handleOepnCamera() {
            const errorMessage = await this.$refs.child.startCamera();
            console.log('是否打开了摄像头', open);
            if (errorMessage) {
                this.$parent.showTost(0, errorMessage);
            } else {
                this.openCamera = !this.openCamera;
            }
        },

        checkKycStepTwo() {
            const that = this;
            if (!that.front) {
                that.$parent.showTost(0, '请上传正面照片');
                return;
            }
            if (!that.back) {
                that.$parent.showTost(0, '请上传背面照片');
                return;
            }
            that.step = 3;
        },
        changtanshow() {
            this.step = 1;
            this.$emit("changeShow", false)
        },
        checkKycStepOne() {
            let that = this;
            console.log("checkKycStepOne", that.tankuangUserName, that.tankuangCid, that.tankuangCidRadio);

            if (!that.tankuangUserName || !that.tankuangCid || !that.tankuangCidRadio) {
                that.$parent.showTost(0, '请完善信息');
                return;
            }

            that.step = 2;

        },
        beforeAvatarUpload(file) {
            const that = this;

            const isJPG = that.accpetFileTypes.includes(file.type);
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG、JPEG、PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }

            if (isJPG && isLt2M) {
                that.$parent.showLoading()
            }
            return isJPG && isLt2M;
        },
        handleCameraPhoto(res) {
            const that = this;
            console.log('handleCameraPhoto', res);

            that.photo = res.data.id;
            that.cameraPhoto = res.data.url;
            this.openCamera = false;
        },
        showLoading() {
            this.$parent.showLoading()
        },
        hideLoading() {
            this.$parent.hideLoading()
        }
    }
}
</script>

<style lang="less" scoped>
.usrse {
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    height: calc(100vh - 46px);


    .nams {
        font-size: 0.38rem;
        color: #000;
        vertical-align: middle;
        margin-top: 10px;
    }

    .imgsa {
        position: relative;
        height: 2rem;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 0.2rem;

        .bisn {
            width: 0.8rem;
            position: absolute;
            bottom: 0.3rem;
            left: 1.4rem;
        }

        img {
            width: 2rem;
            border-radius: 50%;
        }
    }
}

.identityContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    min-height: 200px;
    margin-top: 20px;

    .demoContent {
        display: flex;
        flex-direction: column;
        width: 90%;
        min-height: 180px;
        border: 1px solid red;
    }

    .buttonContent {
        width: 90%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .sc_button_content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px 0;
        }
    }
}

.avatar {
    width: 168px;
    height: 100px;
}
</style>