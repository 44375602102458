<template>
    <div class="smallSwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in vipLis" :key="index">
                <div class="vip-content" @click="selectVip(item.vipLevel)">Lv{{ item.vipLevel }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatDate2, formatNumberQfw } from '@/utils/commonUtil';
// 导入 Swiper 核心库
import Swiper from 'swiper';
export default {
    name: 'vipBigLevel',
    data() {
        return {
            vipLis: [],
            vipTab: 1,
            userInfo: {},
            selectedVipInfo: {},
            step: 0,
            stepWidth: 0,
            headerList: [],
            userVipInfo: {},
            allVipLis: [],
            configData: {},
            mySwiper: null,
        }
    },
    props: {
        selectLevel: {
            type: Number,
            default: 1,
        }
    },
    watch: {
        // 监听 selectLevel 的变化
        selectLevel(newLevel, oldLevel) {
            const that = this;
            console.log('VIP 等级已更改:', oldLevel, '->', newLevel);
            that.selectVip(newLevel);
            // 在这里执行你的逻辑，例如更新状态或发送请求等
        }
    },
    computed: {
        getAmount() {
            const that = this;
            let rTime = ''
            console.log('用户等级和所选等级', that.userInfo.vipLevel, that.selectedVipInfo.vipLevel, that.userInfo.relegationTime);
            if ((that.userInfo.vipLevel || 0) == that.selectedVipInfo.vipLevel && that.userInfo.relegationTime) {
                rTime = formatDate2(Number(that.userInfo.relegationTime), 2)
            }
            const allAmount = {
                needRecharge: formatNumberQfw(that.selectedVipInfo.needRecharge || 0, 2),
                needWager: formatNumberQfw(that.selectedVipInfo.needWager || 0, 2),
                relegationWager: formatNumberQfw(that.selectedVipInfo.relegationWager || 0, 2),
                needRechargePercentage: that.userInfo.curRecharge / that.selectedVipInfo.needRecharge * 100,
                needWagerPercentage: that.userInfo.curWagered / that.selectedVipInfo.needWager * 100,
                relegationWagerPercentage: that.userInfo.relegationWagered / that.selectedVipInfo.relegationWager * 100,
                dailyWithdrawAmount: formatNumberQfw(that.selectedVipInfo.dailyWithdrawAmount, 0) || 0,

                relegationTime: rTime,
                reacharge: formatNumberQfw(that.selectedVipInfo.needRecharge || 0, 0, 0),
                wager: formatNumberQfw(that.selectedVipInfo.needWager || 0, 0, 0),
                relegationWagered: formatNumberQfw(that.selectedVipInfo.relegationWager || 0, 0, 0),
            }
            return allAmount;
        },
    },
    created() {
        const that = this;
        that.getConfitData();
    },
    mounted() {
        if (this.vipLis && this.vipLis.length > 0) {
            this.$nextTick(() => {
                this.initSwiper();
            });
        }
    },
    updated() {
        if (this.vipLis && this.vipLis.length > 0) {
            this.initSwiper();
        }
    },
    methods: {
        selectVip(vipLevel) {
            const that = this;
            that.selectedVipInfo = that.allVipLis.find((item) => item.vipLevel == (vipLevel || 0));
            if (that.mySwiper) {
                that.mySwiper.slideTo(vipLevel - 1);
            }
            that.$emit('handleSelectVip', vipLevel);
        },
        getConfitData() {
            let that = this;
            that.configData = that.$parent.getConfigDatas();
            if (!that.configData || !that.configData.vipClubList) {
                setTimeout(() => {
                    that.getConfitData();
                }, 500);
            } else {

                that.vipLis = that.configData.vipClubList.filter((item) => item.vipLevel > 0);
                // that.vipLis = that.configData.vipClubList

                that.allVipLis = that.configData.vipClubList;
                that.headerList = that.configData.headList
                if (that.vipLis && that.vipLis.length > 0) {
                    that.selectedVipInfo = that.allVipLis.find((item) => item.vipLevel == (that.userInfo.vipLevel || 0));
                    that.vipTab = that.userInfo.vipLevel || 0;
                    that.step = 100 / (that.vipLis.length - 1)
                    that.stepWidth = that.step * (that.userInfo.vipLevel || 0)
                    that.userVipInfo = that.vipLis.find((item) => item.vipLevel == (that.userInfo.vipLevel || 0));
                }
            }
        },
        initSwiper() {
            let that = this;
            if (that.mySwiper) {
                that.mySwiper.destroy(true, true); // 销毁之前的实例
            }
            that.mySwiper = new Swiper('.smallSwiper', {
                allowTouchMove: false, //禁止拖动
                centeredSlides: true, //设置活动块居中
                spaceBetween: 50, //设置slide之间的距离1
                loop: false,
                slidesPerView: 5, //设置slider容器能够同时显示的slides数量(carousel模式)。
                on: {
                    slideChange: function () {
                        if (that.mySwiper && that.mySwiper.activeIndex) {
                            // 当前活动的幻灯片索引
                            const currentIndex = that.mySwiper.realIndex;
                            that.selectedVipInfo = that.vipLis.find((item) => item.vipLevel == currentIndex + 1);
                            console.log("small当前活动的幻灯片索引:", currentIndex, that.selectedVipInfo);
                        }
                    }
                }
            });
            console.log('swiper实例 allowTouchMove', this.mySwiper.params.allowTouchMove); // 检查是否为 false
            console.log('swiper实例 slidesPerView', this.mySwiper.params.slidesPerView); // 检查是否为 5
        },
    }
}
</script>

<style lang="less" scoped>
.smallSwiper {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 36px auto 0;

}

.swiper-wrapper {
    width: 100%;
    height: 100%;

}

.swiper-slide {
    position: relative;
    color: #788b9d;
    font-size: .36rem;
    // margin-left: 10px;

    .vip-content {
        border: .01rem solid #E7EBEE;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        background-color: hsla(208, 17%, 72%, 0.441);
        position: relative;
    }

    .active {
        background-color: #D8B879;
    }

    .vip-content::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 110%;
        width: 120%;
        height: 1px;
        background-color: #10314449;
    }
}
</style>