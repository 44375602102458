<template>
  <div style="width: 100%; min-height: 100vh; background: white;">
    <!-- 顶部导航栏 -->
    <van-nav-bar title="新增USDT地址" left-arrow
      style="position: fixed; top: 0; left: 0; width: 100%; background-color: #000;" @click-left="$router.back()" />
    <!-- 占位，使内容不被顶部导航栏覆盖 -->
    <div style="height: 46px;"></div>

    <!-- 提示信息 -->
    <div class="tipsh">
      <div class="tops">
        USDT价格稳定 流通性高 不受监管
        <span @click="$parent.goNav('/usdtmore')">了解更多 ></span>
      </div>
      <div class="tsg">
        <div class="tsgs">绑定协议地址</div>
        <div class="tsgs">交易所划转</div>
        <div class="tsgs">完成取款</div>
      </div>
    </div>

    <!-- 表单区域 -->
    <div class="usrse">
      <!-- 钱包协议选择 -->
      <div class="hgs">
        <div class="nams sc" v-if="networks && networks.length > 0">
          钱包协议
          <div v-for="(item, index) in networks" :key="index" :class="meyXi == item ? ' ssa acti' : 'ssa'"
            @click="changXiyi(item)">{{ item }}</div>
        </div>
        <div style="border-bottom: 1px solid #f2f2f2;"></div>
      </div>

      <!-- USDT地址输入框 -->
      <div class="hgs">
        <div class="nams">USDT地址</div>
        <div style="border-bottom: 1px solid #f2f2f2;">
          <van-cell-group>
            <van-field v-model="usdtInfo.bank_no" type="text" placeholder="请输入USDT地址" />
          </van-cell-group>
        </div>
      </div>

      <!-- 确认按钮 -->
	   <div class="hgs">
      <van-button type="info" style="margin-top: 20px; width: 100%;" @click="bindUsdss">确认添加</van-button>
	   </div>
    </div>
  </div>
</template>

<script>
import { addDeleteWithdrawAccount, getWithdrawData } from "@/service/billing";
export default {
  name: "addUsdtCard",
  data() {
    return {
      usdtInfo: {
        bank_owner: 'TRC20', // 默认选项
        bank_no: '', // USDT地址
        pay_pass: '', // 支付密码
      },
      currentInfo: {},
      withdrawList: [],
      networks: [],
      meyXi: "ERC20",
    };
  },
  created() {
    const that = this;
    that.getWithdrawData();
  },
  methods: {
    changXiyi(val) {
      if (this.meyXi == val) {
        return;
      }
      this.meyXi = val;
    },
    async getWithdrawData() {
      let that = this;
      try {
        that.$parent.showLoading();
        const res = await getWithdrawData({
          msgID: 400303
        })
        if (res.error > 0) {
          this.showTost(0, res.error);
          return;
        }
        console.log('返回提现数据', res);
        that.withdrawList = res.withdrawList
        if (that.withdrawList && that.withdrawList.length > 0) {
          that.networks = that.withdrawList.find((item) => item.paymentMethod == 300).network
        }
      } finally {
        that.$parent.hideLoading();
      }
    },

    // 切换协议类型
    changXie(protocol) {
      this.usdtInfo.bank_owner = protocol;
    },
    // 确认添加USDT地址
    async bindUsdss() {
      let that = this;
      let usdtInfo = that.usdtInfo;
      usdtInfo.bank = 'USDT';
      if (usdtInfo.bank_no == null || usdtInfo.bank_no == '') {
        that.$parent.showTost(0, '请输入USDT地址');
        return;
      }
      if (!that.meyXi) {
        that.$parent.showTost(0, '请选择钱包协议');
        return;
      }
      // if (!usdtInfo.pay_pass) {
      //   that.$parent.showTost(0, '请输人支付密码');
      //   return;
      // }
      that.$parent.showLoading();
      const withdraw = that.withdrawList.find((item) => item.paymentMethod == 300)
      const res = await addDeleteWithdrawAccount({
        msgID: 400309,
        type: 1,
        currencyId: withdraw.currencyId,
        paymentMethod: withdraw.paymentMethod,
        network: that.meyXi,
        withdrawAccount: {
          extend_0: that.meyXi,
          extend_1: usdtInfo.bank_no,
        }
      })
      if (res.error > 0) {
        that.$parent.showTost(0, res.error);
        this.hideLoading();
        return;
      }
      console.log('请求添加提现账户返回', res);
      that.$parent.showTost(1, res.message);

      that.$parent.showTost(1, '绑定成功');
      that.$router.back();

      that.$parent.hideLoading();
    },
    // 处理输入框的只读属性
    removeReadonly(event) {
      event.target.removeAttribute('readonly');
    },
    addReadonly(event) {
      event.target.setAttribute('readonly', true);
    }
  }
};
</script>

<style lang="scss" scoped>
/* 提示信息样式 */
.tipsh {
  padding: 1rem;
  background-color: #ffffff;
}

.tops {
  font-size: 0.36rem;
  color: #333;
}

.tsg {
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
}

.tsgs {
  font-size: 0.4rem;
  color: #666;
}

/* 协议选择按钮样式 */
.usrse {
  background: #fff;
  box-sizing: border-box;
  padding-top: 5px;

  .hgs {
    width: calc(100% - 40px);
    margin: 0 auto;
  }

  .nams {
    font-size: 0.38rem;
    color: #000;
    vertical-align: middle;
    margin-top: 10px;
    font-weight: 700;
  }

  .imgsa {
    position: relative;
    height: 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 0.2rem;

    .bisn {
      width: 0.8rem;
      position: absolute;
      bottom: 0.3rem;
      left: 1.4rem;
    }

    img {
      width: 2rem;
      border-radius: 50%;
    }
  }
}

.sc {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  .ssa {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    width: 2rem;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.4rem;
    font-weight: 700;
    text-align: center;
    margin-left: 1rem;
  }

  .acti {
    color: #dec562 !important;
    border: 1px solid #dec562!important;
  }
}
</style>
