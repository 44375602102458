<template>
  <div style="width: 100%; min-height: 100vh; background: white;">
    <van-nav-bar
      title="协议的区别"
      left-arrow
      style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd"
      @click-left="$router.back()"
    />
    <div style="height: 46px;"></div>

    <div class="virtualProto__virtualCurrency__b7CGB">
      <div class="virtualProto__block__3o4T3">
        <div class="virtualProto__top_img__2l2NV" style="background-image: url('/static/image/title-bg6.png'); width: 206px;">
          协议介绍
        </div>
        <div class="virtualProto__firstPart__1A0Z-">
          <span>TRC20：基于波场网络协议</span>
          <p>TRC20的提币手续费最低，这意味着用户可以享受低手续费的交易所提币服务。同时，波场网络的TPS能够达到上千级别，可以实现交易秒级确认。</p>
        </div>
        <div class="virtualProto__firstPart__1A0Z-">
          <span>ERC20：基于以太坊网络协议</span>
          <p>以太坊发行自己的原生代币及其他代币。但成千上万种代币的规则都不一样，这对后期市场发展非常不利。所以代币发行者做了一个智能合约标准，也就是ERC20。</p>
        </div>
      </div>

      <div class="virtualProto__block__3o4T3">
        <div class="virtualProto__top_img__2l2NV" style="background-image: url('/static/image/title-bg6.png'); width: 206px;">
          协议的区别？
        </div>
        <table>
          <thead>
            <tr>
              <th>区别点</th>
              <th>TRC20协议</th>
              <th>ERC20协议</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>地址样式</td>
              <td>TRC20-USDT地址以T开头</td>
              <td>ERC20-USDT地址以0x开头</td>
            </tr>
            <tr>
              <td>使用网络</td>
              <td>波场网络</td>
              <td>以太坊网络</td>
            </tr>
            <tr>
              <td>网络状态</td>
              <td>基本不堵</td>
              <td>经常拥堵</td>
            </tr>
            <tr>
              <td>转账速度</td>
              <td>极快 (几秒到几分钟)</td>
              <td>普通 (几分钟到数十分钟)</td>
            </tr>
            <tr>
              <td>手续费用</td>
              <td>低</td>
              <td>普通</td>
            </tr>
            <tr>
              <td>安全系数</td>
              <td>普通</td>
              <td>高</td>
            </tr>
            <tr>
              <td>使用建议</td>
              <td>小额高频交易推荐</td>
              <td>中等额度常规交易推荐</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="virtualProto__block__3o4T3">
        <div class="virtualProto__top_img__2l2NV" style="background-image: url('/static/image/title-bg6.png'); width: 206px;">
          到底选哪种协议更好？
        </div>
        <div class="virtualProto__container__3gv6Q">
          <img src="/static/image/pic-xiaoe@2x.png" width="72px" />
          <div class="virtualProto__rightContent__3OhXd">
            <span>小额交易推荐</span>
            <div>低手续费，秒级到账。</div>
          </div>
        </div>
        <div class="virtualProto__container__3gv6Q">
          <img src="/static/image/pic-zhonge@2x.png" width="72px" />
          <div class="virtualProto__rightContent__3OhXd">
            <span>中等额度推荐</span>
            <div>手续费和速度都介于中间值。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProtocolComparison",
  methods: {
    // 可添加相关逻辑
  },
};
</script>

<style scoped>
.virtualProto__block__3o4T3 {
  margin: 20px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.virtualProto__top_img__2l2NV {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding: 10px 0;
}
.virtualProto__firstPart__1A0Z- span {
  font-weight: bold;
  font-size: 16px;
}
.virtualProto__container__3gv6Q {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.virtualProto__rightContent__3OhXd span {
  font-size: 16px;
  font-weight: bold;
}
.virtualProto__rightContent__3OhXd div {
  color: #666;
}
</style>
