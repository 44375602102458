<template>
  <div style="width: 100%; min-height: 100vh; background: white;">
    <!-- 顶部导航栏 -->
    <van-nav-bar title="帮助中心" left-arrow
      style="position: fixed; top: 0; left: 0; width: 100%; background-color: #000;" @click-left="$router.back()" />
    <!-- 顶部间距 -->
    <div style="height: 46px"></div>

    <!-- 欢迎信息 -->
    <div class="topsf">
      <div class="tois">
        <img src="/static/style/tx.0d38194b71b5a32ef1df50b7090ca7f5.webp" alt="" />
        <div class="dwd">
          <div class="tisaa">Hi, 尊敬的会员用户</div>
          <div class="newsa">早上好，欢迎来到帮助中心</div>
        </div>
      </div>
      <div class="rxg">
        若相关问题仍未解决，可咨询在线客服
      </div>
    </div>

    <!-- 菜单选项 -->
    <div class="bosfs">
      <div 
        class="hgsw" 
        v-for="(item, index) in articlesList" 
        :key="index"
        :style="{ border: index === articlesList.length - 1 ? 'none' : '' }"
        @click="goNav('/boutBallBetInfo/'+ item.title)"
      >
        <img class="firsimg" :src="item.icon" alt="" />
        <span class="tit">{{ item.title }}</span>
        <span class="tisf"></span>
        <img class="rigiong" src="/static/style/icoArrowGrey@2x.abc43ab099390c4587d33290a76fc15e.png" alt="" />
      </div>
    </div>

    <!-- 底部客服提示 -->
    <div style="margin-top: 0.48rem; text-align: center; color: #6c7c9d; padding-bottom: 0.6rem; font-size: 0.3rem;" >
      没有找到解决办法？请联系
      <a style="color: #e0bc4e; font-weight: 600" @click="openKefu">人工客服</a>解决
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1, // 默认选择第一个
      articlesList: [],
      title: null,
      current_content:null,
    };
  },
  created() {
    const that = this;
    that.getConfitData()
  },
  methods: {
    getConfitData() {
      let that = this;
      that.configData = that.$parent.getConfigDatas();
      if (!that.configData || !that.configData.exchangeRateInfo) {
        setTimeout(() => {
            that.getConfitData();
        }, 500);
      } else {
        that.articlesList = that.configData.articlesList
        console.log('获取到配置信息为---文章信息', that.articlesList );
        that.title = that.articlesList[0].title;
        that.current_content = that.articlesList[0].content;
      }
    },
    goNav(path) {
      this.$router.push(path);
    },
    openKefu() {
      this.$parent.openKefu();
    }
  }
}
</script>

<style lang="less" scoped>
.topsf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tois {
     display: flex;
     flex-direction: row;
     column-gap: 10px;
     align-items: center;

     .dwd {
      font-size: 15.64px;
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      
      .tisaa {
        font-weight: 700;
      }
      .newsa {
         font-size: 13px;
         color: #5d607e;
      }
     }
     
     img {
       width: 68px;
       height: 70px;
     }
  }
  .rxg {
    margin: .3rem auto 0;
    line-height: .44rem;
    font-size: .34rem;
    text-align: center;
    border-top: .02rem solid #eee;
    padding: .3rem .2rem;
    color: #4b5e6f;
    width: 90%;
  }
}


.hgsw {
  display: flex;
  align-items: center;
  padding: .6rem;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.firsimg {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
}

.tit {
  font-size: 16px;
  color: #333;
}

.rigiong {
  margin-left: auto;
  width: 12px;
  height: 12px;
}
</style>
