<template>
  <div class="app app-ti_green">
    <!-- Header -->
    <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd" title="加入我们"
      left-arrow @click-left="$router.back()" />
    <div style="height: 46px"></div>

    <!-- Main Content -->
    <div class="pahsn">
      <div
        style="display: flex; flex-direction: column; align-items: center; font-size:.53rem; padding-top: 20px; row-gap: 15px;">
        <span style="font-weight: 600;">代理合营计划</span>
        <span style="font-size:.33rem; color: #788b9d;">是成为传奇？还是传奇的歌颂者？</span>
      </div>
      <img style="display: block; width: 95%; margin: 0 auto;" src="/static/image/02.png" />

      <div class="opern" v-if="step == 1">
        <div class="row" v-for="(item, index) in customerServiceList" :key="index">
          <img :src="item.icon" alt="" class="icon">
          <div class="main">
            <div class="name">
              <span>{{ item.name }}</span>
            </div>
            <div class="sec">
              点击进入在线咨询
            </div>
          </div>
          <div class="link link-copy" v-if="item.contactDetails">
            复制
          </div>
          <div class="link btn-blue" v-if="item.links" @click="openLink(item.links)">
            咨询
          </div>
        </div>
      </div>

      <!-- Customer Service Button -->
      <!-- <div class="zixun" @click="$parent.openKefu">
        <img src="/static/image/16044962635685155.png" />
        <div class="cnets">
          <div class="tos">合营部</div>
          <div class="bos">立即咨询</div>
        </div>
        <div class="zusnb">咨询</div>
      </div> -->

      <!-- Form Section -->
      <div class="bsd">
        <van-form v-if="step != 1">
          <van-field label="真实姓名" placeholder="请完善个人资料中的真实姓名" :value="$store.state.userInfo.realName" disabled />
          <van-field label="联系方式" v-model="maskedPhone" placeholder="请输入您的联系方式" />
          <van-field label="申请理由" v-model="info.apply_info" placeholder="请输入申请说明" />
        </van-form>
        <van-button block type="info" @click="handleClick" style="margin-top: 10px;">
          加入我们
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { applyAgent, refreshPlayerData } from '@/service/hall';
export default {
  data() {
    return {
      info: {
        mobile: '',
        apply_info: '',
      },
      customerServiceList: [],
      step: 1,
      firstShowPhone: true,
      userInfo: {}
    };
  },
  computed: {
    maskedPhone: {
      get() {
        console.log('调用get', this.mobile, this.firstShowPhone);
        if (this.firstShowPhone) {
          return this.maskPhone(this.mobile);
        }
        return this.mobile;
      },
      set(val) {
        this.firstShowPhone = false;
        this.mobile = val;
      }
    }

  },
  created() {
    const that = this;
    that.getConfitData();
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank');
    },
    handleClick() {
      const that = this;
      if (that.step == 1) {
        that.step = 2;
      } else {
        that.shenqing();
      }
    },

    async freshUserInfo() {
      const that = this;
      const res = await refreshPlayerData({
        msgID: 400033
      });
      localStorage.setItem('userInfo', JSON.stringify(res.playerInfo));
      that.$store.commit('changUserInfo');
      that.mobile = res.playerInfo.phone;
      that.userInfo = res.playerInfo;
      console.log('获得用户手机号为:', that.mobile);
    },
    maskPhone(str) {
      if (!str) {
        return '';
      }
      return str.slice(0, 3) + '***' + str.slice(str.length - 3);
    },
    getConfitData() {
      let that = this;
      const configData = that.$parent.getConfigDatas();
      console.log('进入applyagent', configData);
      if (!configData || !configData.customerServiceList) {
        setTimeout(() => {
            that.getConfitData();
        }, 500);
      } else {
        that.customerServiceList = configData.customerServiceList.filter(item => item.showIndex === 2);
        console.log('customerServiceList', that.customerServiceList);
        
      }
    },
    async shenqing() {
      const that = this;
      let info = that.info;

      if (!that.userInfo.realName) {
        that.$parent.showTost(0, '真实姓名不能为空');
        return;
      }
      if (!info.apply_info) {
        that.$parent.showTost(0, '请输入申请理由');
        return;
      }
      
      // that.close();

      try {
        that.$parent.showLoading();
        const res = await applyAgent({
          msgID: 400029,
          name: that.userInfo.realName,
          phone: that.mobile,
          desc: info.apply_info,
        });
        if (res.error > 0) {
          that.$parent.showTost(0, res.error);
          return;
        }
        that.$parent.showTost(1, '申请成功，请等待工作人员审核');
      } finally {
        that.$parent.hideLoading()
      }
    },
  }
};
</script>

<style lang="less" scoped>
.app-ti_green {
  background-color: #fff9ed;
}

.header {
  background-color: #fff9ed;
}

.header__top-wrapper {
  position: relative;
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.van-nav-bar__left {
  cursor: pointer;
}

.van-icon-arrow-left {
  font-size: 16px;
}

.pahsn img {

  display: block;
}

.pahsn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zixun {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px auto;
}

.zixun img {
  width: 20px;
  height: 20px;
}

.cnets {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.tos,
.bos {
  font-size: 14px;
  color: #bd171b;
}

.zusnb {
  color: #bd171b;
  font-weight: 600;
}

.bsd {
  padding: 20px;
  background-color: #fff9ed;
  border-radius: 8px;
  margin: 20px auto;
  width: 90%;
}

.opern {
  width: 90vw;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .row {
    margin: .16rem auto 0;
    padding: .3rem .24rem .3rem .32rem;
    width: 92%;
    border-radius: .16rem;
    background: linear-gradient(180deg, #fff, #f9f9ff 88.29%, #fff);
    border: .02rem solid #fff;
    border-radius: .2rem;
    box-shadow: 0 8px 12px 0 rgba(53, 120, 246, .08);
    display: flex;
    align-items: center;

    .icon {
      width: .74rem;
      height: .74rem;
    }

    .main {
      flex-direction: column;
      display: flex;
      flex: 1;
      margin-left: .28rem;
      padding-left: .28rem;
      position: relative;

      .name {
        color: #404261;
        font-size: .34rem;
      }

      .sec {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #6a7391;
        font-size: .28rem;
        line-height: .4rem;
        height: .4rem;
      }
    }

    .main::before {
      position: absolute;
      content: "";
      left: 0;
      background: #eee;
      top: .06rem;
      bottom: .06rem;
      width: 1px;
    }

    .link {
      width: 1.36rem;
      height: .6rem;
      line-height: .6rem;
      border-radius: .4rem;
      font-size: .3rem;
      color: #fff;
      margin-left: .22rem;
      text-align: center;
      box-shadow: 0 3px 7px 0 rgba(121, 152, 252, .35);
    }

    .link-copy {
      color: black;
      background: #fff;
    }

    .btn-blue {
      width: 1.36rem;
      height: .88rem;
      font-size: .32rem;
      background: linear-gradient(90deg, #86a8ff,#4b72f6);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .42rem;
    }

  }
}
</style>
