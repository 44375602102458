<template>
  <van-tabbar :active-color="'#bd171b'" :inactive-color="'#e9d890'" :style="{
    borderTop: '1px solid #e9d890',
    boxSizing: 'border-box',
    backgroundColor: '#000',
    zIndex: '200'
  }" route v-model="type">

    <van-tabbar-item to="/home">
      <template #icon="props">
        <img style="width: 32px;height: 32px;" :src="props.active ? '/static/image/tabbar_icon1_select.png' : '/static/image/tabbar_icon1_nor.png'" />
      </template>
      <span>首页</span>
    </van-tabbar-item>

    <van-tabbar-item to="/activity">
      <template #icon="props">
        <img style="width: 32px;height: 32px;" :src="props.active ? '/static/image/tabbar_icon2_select.png' : '/static/image/tabbar_icon2_nor.png'" />
      </template>
      <span>优惠</span>
    </van-tabbar-item>

    <van-tabbar-item to="/kefu">
      <template #icon="props">
        <img style="width: 32px;height: 32px;" :src="props.active ? '/static/image/tabbar_icon3_select.png' : '/static/image/tabbar_icon3_nor.png'" />
      </template>
      <span>客服</span>
    </van-tabbar-item>

    <van-tabbar-item to="/mine">
      <template #icon="props">
        <img style="width: 32px;height: 32px;" :src="props.active ? '/static/image/tabbar_icon5_select.png' : '/static/image/tabbar_icon5_nor.png'" />
      </template>
      <span>我的</span>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      type: 0, // 初始选中项
    };
  },
};
</script>

<style scoped>
::v-deep .van-tabbar-item--active {
  background-color: #272727 !important;
}
</style>