<template>
  <div style="height: calc(100vh - 50px); overflow-y: scroll; -webkit-overflow-scrolling: touch;">
    <div class="button_kefu button_kefu_show">
        <div class=" close_button">
      <img src="/static/image/imgpsh_fullsize_anim3.png" alt="" style="height: 30px; object-fit: contain; ;">
      <i @click="closeKefu" class="el-icon-close" style="color: white; font-size: 24px; cursor: pointer;"></i>
    </div>
    <div class="text_content">
      <span style="font-size: 36px; color: #e4ca99;">欢迎来到明陞88</span>
      <span style="font-size: 36px;">👋</span>
      <span style="font-size: 36px; color: white;">需要帮助请联系我们</span>
    </div>
    <div style="overflow: auto; display: flex; flex-direction: column; row-gap: 15px; ">
      <div @click="openLink(item.links)" class="send_message" v-for="(item, index) in customerServiceList" :key="index">
        <img :src="item.icon" alt="" style="height: 32px; object-fit: contain;">
        <span>{{ item.name }}</span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16"
            color="linkColor">
            <path fill="#e4ca99" fill-rule="evenodd"
              d="m4.563 14.605 9.356-5.402c1-.577 1-2.02 0-2.598L4.563 1.203a1.5 1.5 0 0 0-2.25 1.3v10.803a1.5 1.5 0 0 0 2.25 1.3M6.51 8.387 2.313 9.512V6.297L6.51 7.42c.494.133.494.834 0 .966"
              clip-rule="evenodd"></path>
          </svg>
        </span>

      </div>
    </div>

  </div>
  </div>
</template>

<script>
import { getConfigData } from '@/service/backStage';
export default {
  data() {
    return {
      showKefu: false,
      customerServiceList: null
    }
  },
  created() {
    const that = this;
    that.getConfigDataList();
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank');
    },
    closeKefu() {
      this.$store.commit('changeShowKefu', false);
    },
    async getConfigDataList() {
      let that = this;
      const res = await getConfigData({
        msgID: 400501
      })
      console.log('helpcenter获得配置数据-->', res);

      if (res.error > 0) {
        that.$parent.showTost(0, res.error);
        return
      }

      that.customerServiceList = res.customerServiceList.filter(item => item.showIndex === 1);
      console.log('helpcenter获得配置数据-->', res.customerServiceList);
      return res
    },
  },
  computed: {
    isEdit() {
      return this.$store.state.showKefu;
    }
  },
  watch: {
    "$store.state.showKefu": {
      handler(newVal, oldVal) {
        this.showKefu = Boolean(newVal);
        console.log('客服showKefu changed:', this.showKefu);
      }
    }
  }
};

</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
  /* Safari 和 Chrome */
}

/* 适用于 Firefox */
* {
  scrollbar-width: none;
  /* Firefox */
}

.button_kefu {
  position: relative;
  background: linear-gradient(to bottom, #2b1717, #f5f5f5);
  transition: all 0.6s;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 10px;
}

.button_kefu .close_button {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.button_kefu .text_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.button_kefu .send_message {
  width: 100%;
  height: 53px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 20px;
  flex-shrink: 0;
}
</style>
