<template>
    <div class="vipSwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in vipLis" :key="index">
                <img :src="`/static/image/viptq/vip_big_${item.vipLevel}.png`" class="vipDetail__badgeIcon__znE_X"
                    alt="">
                <div class="vipDetail__level__Xcum3">VIP{{ item.vipLevel }}</div>
                <div class="vipDetail__cardInfoGroup__gKw0l vipDetail__withDeposit___CJ6G">
                    <div class="vipDetail__cardInfoItem__rm3HA">
                        <div class="vipDetail__value__SIWk7">{{ getAmount.reacharge }}</div>
                        <div class="vipDetail__text__hg8WK">累计存款</div>
                    </div>
                    <div class="vipDetail__cardInfoItem__rm3HA">
                        <div class="vipDetail__value__SIWk7">{{ getAmount.wager }}</div>
                        <div class="vipDetail__text__hg8WK">流水要求</div>
                    </div>
                    <div class="vipDetail__cardInfoItem__rm3HA">
                        <div class="vipDetail__value__SIWk7">{{ getAmount.relegationWagered }}</div>
                        <div class="vipDetail__text__hg8WK">保级流水三个月</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add Arrows -->
    </div>
</template>

<script>
import { formatDate2, formatNumberQfw } from '@/utils/commonUtil';
// 导入 Swiper 核心库
import Swiper from 'swiper';
export default {
    name: 'vipBigLevel',
    data() {
        return {
            vipLis: [],
            vipTab: 1,
            userInfo: {},
            selectedVipInfo: {},
            step: 0,
            stepWidth: 0,
            headerList: [],
            userVipInfo: {},
            allVipLis: [],
            configData: {},
            mySwiper: null,
        }
    },
    props: {
        selectLevel: {
            type: Number,
            default: 0,
        }
    },
    watch: {
        // 监听 selectLevel 的变化
        selectLevel(newLevel, oldLevel) {
            const that = this;
            console.log('VIP 等级已更改:', oldLevel, '->', newLevel);
            that.selectVip(newLevel);
            // 在这里执行你的逻辑，例如更新状态或发送请求等
        }
    },
    computed: {

        getAmount() {
            const that = this;
            let rTime = ''
            console.log('用户等级和所选等级', that.userInfo.vipLevel, that.selectedVipInfo.vipLevel, that.userInfo.relegationTime);
            if ((that.userInfo.vipLevel || 0) == that.selectedVipInfo.vipLevel && that.userInfo.relegationTime) {
                rTime = formatDate2(Number(that.userInfo.relegationTime), 2)
            }
            const allAmount = {
                needRecharge: formatNumberQfw(that.selectedVipInfo.needRecharge || 0, 2),
                needWager: formatNumberQfw(that.selectedVipInfo.needWager || 0, 2),
                relegationWager: formatNumberQfw(that.selectedVipInfo.relegationWager || 0, 2),
                needRechargePercentage: that.userInfo.curRecharge / that.selectedVipInfo.needRecharge * 100,
                needWagerPercentage: that.userInfo.curWagered / that.selectedVipInfo.needWager * 100,
                relegationWagerPercentage: that.userInfo.relegationWagered / that.selectedVipInfo.relegationWager * 100,
                dailyWithdrawAmount: formatNumberQfw(that.selectedVipInfo.dailyWithdrawAmount, 0) || 0,

                relegationTime: rTime,
                reacharge: formatNumberQfw(that.selectedVipInfo.needRecharge || 0, 0, 0),
                wager: formatNumberQfw(that.selectedVipInfo.needWager || 0, 0, 0),
                relegationWagered: formatNumberQfw(that.selectedVipInfo.relegationWager || 0, 0, 0),
            }
            return allAmount;
        },
    },
    created() {
        const that = this;
        that.getConfitData();
    },
    mounted() {
        this.$nextTick(() => {
            this.initSwiper();
        });
    },
    methods: {
        selectVip(vipLevel) {
            const that = this;
            that.selectedVipInfo = that.allVipLis.find((item) => item.vipLevel == (vipLevel || 0));
            if (that.mySwiper) {
                that.mySwiper.slideTo(vipLevel - 1);
            }
        },
        getConfitData() {
            let that = this;
            that.configData = that.$parent.getConfigDatas();
            if (!that.configData || !that.configData.vipClubList) {
                setTimeout(() => {
                    that.getConfitData();
                }, 500);
            } else {

                that.vipLis = that.configData.vipClubList.filter((item) => item.vipLevel > 0);
                // that.vipLis = that.configData.vipClubList
                console.log('获取到配置信息为---vip信息', that.vipLis);
                that.allVipLis = that.configData.vipClubList;
                that.headerList = that.configData.headList
                if (that.vipLis && that.vipLis.length > 0) {
                    that.selectedVipInfo = that.allVipLis.find((item) => item.vipLevel == (that.userInfo.vipLevel || 0));
                    that.vipTab = that.userInfo.vipLevel || 0;
                    that.step = 100 / (that.vipLis.length - 1)
                    that.stepWidth = that.step * (that.userInfo.vipLevel || 0)

                    that.userVipInfo = that.vipLis.find((item) => item.vipLevel == (that.userInfo.vipLevel || 0));
                }

            }
        },
        initSwiper() {
            let that = this;
            that.mySwiper = new Swiper('.vipSwiper', {
                centeredSlides: true, //设置活动块居中
                spaceBetween: 20, //设置slide之间的距离
                loop: false,
                slidesPerView: 1.3, //设置slider容器能够同时显示的slides数量(carousel模式)。

                on: {
                    // slideChange: function () {
                    //     if (that.mySwiper && that.mySwiper.activeIndex) {
                    //         // 当前活动的幻灯片索引
                    //         const currentIndex = that.mySwiper.activeIndex;
                    //         that.selectedVipInfo = that.vipLis.find((item) => item.vipLevel == currentIndex + 1);
                    //         console.log("bigLevel组件,当前活动的幻灯片索引:", currentIndex, that.selectedVipInfo);
                    //         that.$emit('handleSelectVip', currentIndex + 1);
                    //     }
                    // },
                    // init: function () {
                    //     console.log('bigLevel组件,初始化活动幻灯片索引:', this.activeIndex);
                    // },
                    slideChangeTransitionEnd: function () {
                        console.log('bigLevel组件,当前活动幻灯片索引:', this.activeIndex);
                        if (that.mySwiper) {
                            // 当前活动的幻灯片索引
                            const currentIndex = that.mySwiper.activeIndex;
                            that.selectedVipInfo = that.vipLis.find((item) => item.vipLevel == currentIndex + 1);
                            console.log("bigLevel组件,当前活动的幻灯片索引:", currentIndex, that.selectedVipInfo);
                            that.$emit('handleSelectVip', currentIndex + 1);
                        }
                    },
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
.vipSwiper {
    width: 100%;
    height: 154px;
    position: relative;
    overflow: hidden;
    margin: 24px auto 0;
}

.swiper-wrapper {
    width: 100%;
    height: 100%;
}

.myCenterCommon__userContent__2fHEF {
    padding: 20px !important;
    min-height: unset !important;
    width: 960px !important;
}

.before_page_text {
    cursor: pointer;
}

.before_page_text:hover {
    opacity: 0.6;
}

.swiper-slide {
    background-image: url(/static/image/viptq/vip_card_tag_big_nor.a1b1a7.webp);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    height: 154px;
    position: relative;
    width: 90vw;
}

.vipDetail__badgeIcon__znE_X {

    position: absolute;
    right: 50px;
    top: 0;
    width: 86px;
}

.vipDetail__level__Xcum3 {
    color: #fff;
    font-family: DINPro !important;
    font-size: 36px;
    line-height: 28px;
    margin: 22px 0 50px 42px;
}

.vipDetail__cardInfoGroup__gKw0l {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
}

.vipDetail__withDeposit___CJ6G {
    padding: 0 40px;
}

.vipDetail__cardInfoGroup__gKw0l .vipDetail__cardInfoItem__rm3HA {
    text-align: center;
}

.vipDetail__cardInfoGroup__gKw0l .vipDetail__cardInfoItem__rm3HA .vipDetail__value__SIWk7 {
    color: #fff;
    font-family: Din !important;
    font-size: .34rem;
    line-height: 1.25;
    margin-bottom: 5px;
}

.vipDetail__cardInfoGroup__gKw0l .vipDetail__cardInfoItem__rm3HA .vipDetail__text__hg8WK {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    opacity: .6;
}

.vipDetail__tableWrap__ifEXT {
    box-sizing: border-box;
    margin-bottom: 16px;
    padding-top: 13px;
    width: 100%;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ {
    border-radius: 4px;
    margin-top: 16px;
    overflow: hidden;
    width: 100%;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N {
    align-items: center;
    background: #ebeff7;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N .vipDetail__th__ggOID:first-child {
    min-width: 84px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N .vipDetail__th__ggOID {
    color: #1d1d1f;
    font-family: DINPro-Medium !important;
    font-size: 14px;
    font-weight: 500;
    height: 54px;
    line-height: 54px;
    text-align: center;
    width: 74px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N .vipDetail__th__ggOID.vipDetail__vipGrade__H3szF {
    background: #e4ca99;
    position: relative;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__thead__HeGuQ .vipDetail__tr__w8K2N .vipDetail__th__ggOID.vipDetail__vipGrade__H3szF img {
    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN {
    scrollbar-width: none;
    -ms-overflow-style: none;
    /* IE、Edge */
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}


.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N:nth-child(odd) {
    background: rgba(235, 239, 247, .2);
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N .vipDetail__td__Ru8_Z:first-child {
    min-width: 84px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N .vipDetail__td__Ru8_Z {
    color: #1d1d1f;
    font-family: DINPro !important;
    font-size: 14px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    vertical-align: initial;
    width: 74px;
}

.vipDetail__tableWrap__ifEXT .vipDetail__table__VmMzZ .vipDetail__tbody__bVKqN .vipDetail__tr__w8K2N .vipDetail__td__Ru8_Z.vipDetail__activeTd__Vb4fi {
    background: #e4ca99;
    color: #fff;
    font-family: DINPro-Medium !important;
    font-weight: 500;
}

.user-box:last-child {
    flex: 1 1;
}

.user-box {
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    overflow: visible;
    padding: 20px;
    box-shadow: 0 4px 16px rgba(152, 161, 182, .04);
}

.user-box,
.user-box-h3 .money-main {
    width: 100%;
}

.user-box-title {
    font-family: PingFangSC-Medium;
    font-weight: 700;
    color: #30383f;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.detail-main ul {
    margin-top: 26px;
}

.detail-main li {
    width: 100%;
    height: auto;
    margin-bottom: 18px;
    counter-increment: my-awesome-counter;
    padding-left: 24px;
    position: relative;
    line-height: 22px;
    font-size: 14px;
    color: #48535e;
}

.detail-main li:before {
    content: counter(my-awesome-counter);
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: #e4ca99;
    position: absolute;
    top: 3px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vipDetail__detailWrap__shgf_ .vipDetail__sectionTitle__ulPYk {
    color: #1d1d1f;
    font-family: DinProBold, MicrosoftYaHei, PingFang SC !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.vipDetail__detailWrap__shgf_ .vipDetail__subSectionTitle__LMuiK {
    display: flex;
    font-size: 16px;
}

.vipDetail__ruleGroup__sadx2 {
    margin-top: 16px;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleItem__m0ITw {
    box-sizing: border-box;
    margin-bottom: 24px;
    padding-left: 24px;
    position: relative;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleItem__m0ITw .vipDetail__index__sCXYd {
    background: #e4ca99;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    height: 16px;
    left: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 3px;
    width: 16px;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleItem__m0ITw .vipDetail__text__hg8WK {
    color: #1d1d1f;
    font-size: 14px;
    line-height: 22px;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleMark__E9xjB {
    margin-top: 16px;
    padding-left: 24px;
}

.vipDetail__ruleGroup__sadx2 .vipDetail__ruleMark__E9xjB .vipDetail__mark__oJQCN {
    color: #bd171b;
    font-size: 14px;
    line-height: 22px;
}

.swiper-button-next {
    right: 10px;
}

.swiper-button-prev {
    left: 10px;
}
</style>