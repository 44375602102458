<template>
  <div class="sdddd">
    <div style="height: 46px"></div>
    <!-- 顶部导航栏 -->
    <van-nav-bar title="帮助中心" left-arrow
      style="position: fixed; top: 0; left: 0; width: 100%; background-color: #000;" @click-left="$router.back()" />
    <!-- 顶部间距 -->
    <div style="height: 46px"></div>
    <div class="topsf">
      <div class="tois">
        <img src="/static/style/tx.0d38194b71b5a32ef1df50b7090ca7f5.webp" alt="" />
        <div class="dwd">
          <div class="tisaa">Hi,尊敬的会员用户</div>
          <div class="newsa">早上好，欢迎来到帮助中心</div>
          <div class="newsa">若相关问题仍未解决，可咨询在线客服</div>
        </div>
      </div>
      <div style="color: #fff; text-align: center; margin-top: 10px; font-size: 20px">
        {{ title }}
      </div>
    </div>
    <div
      v-html="current_content"
      style="color: #666; padding: 0px 20px; box-sizing: border-box;font-size: 14px;"
    ></div>
    <div
      v-if="current_content"
      style="margin-top: 0.48rem; text-align: center; color: #6c7c9d; padding-bottom: 0.6rem; font-size: 0.3rem;"
    >
      没有找到解决办法？请联系
      <a
        style="color: #e0bc4e; font-weight: 600; "
        @click="$parent.openKefu"
        
        >人工客服</a
      >
      解决
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: this.$route.params.title, // 示例标题
      current_content: ''
    };
  },
  created() {
    const that = this;
    that.getConfitData()
  },
  methods: {
    getConfitData() {
      let that = this;
      that.configData = that.$parent.getConfigDatas();
      if (!that.configData || !that.configData.exchangeRateInfo) {
        setTimeout(() => {
            that.getConfitData();
        }, 500);
      } else {
        that.articlesList = that.configData.articlesList
        console.log('获取到配置信息为---文章信息', that.articlesList );
        that.current_content = that.articlesList.find(item => item.title === that.title).content;
      }
    },
    goNav(path) {
      this.$router.push(path);
    },
    openKefu() {
      this.$parent.openKefu();
    }
  }
};
</script>


<style scoped>
.topsf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tois {
     display: flex;
     flex-direction: row;
     column-gap: 10px;
     align-items: center;

     .dwd {
      font-size: 15.64px;
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      
      .tisaa {
        font-weight: 700;
      }
      .newsa {
         font-size: 13px;
         color: #5d607e;
      }
     }
     
     img {
       width: 68px;
       height: 70px;
     }
  }
  .rxg {
    margin: .3rem auto 0;
    line-height: .44rem;
    font-size: .34rem;
    text-align: center;
    border-top: .02rem solid #eee;
    padding: .3rem .2rem;
    color: #4b5e6f;
    width: 90%;
  }
}
.tois {
  display: flex;
  align-items: center;
}
.dwd {
  margin-left: 10px;
}
.tisaa {
  font-size: 18px;
  font-weight: bold;
}
.newsa {
  font-size: 14px;
  color: #aaa;
}
</style>
