var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","min-height":"100vh","background":"white"}},[_c('van-nav-bar',{staticStyle:{"position":"fixed","top":"0","left":"0","width":"100%","background-color":"#000"},attrs:{"title":"个人资料","left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('div',{staticStyle:{"height":"46px"}}),_vm._m(0),_c('div',{staticClass:"boxst"},[_c('div',{staticClass:"hgs",on:{"click":function($event){return _vm.$parent.goNav('/userInfo')}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"hgs",on:{"click":function($event){return _vm.$parent.goNav('/wallet')}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"hgs",on:{"click":function($event){return _vm.$parent.goNav('/password?type=1')}}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"hgs",on:{"click":function($event){return _vm.$parent.goNav('/password?type=2')}}},[_vm._m(7),_vm._m(8)]),_c('div',{staticClass:"hgs",on:{"click":function($event){_vm.userInfo.kycAuth != 1 && _vm.userInfo.kycAuth != 2 && _vm.$parent.goNav('/kycAuth')}}},[_c('div',{staticClass:"lfs"},[_c('div',{staticClass:"topas"},[_vm._v("KYC认证")]),(!_vm.userInfo.kycAuth || _vm.userInfo.kycAuth == 0)?_c('span',{staticClass:"tisg"},[_vm._v("认证个人信息，提交身份证明。确保您的账户安全")]):_vm._e(),(_vm.userInfo.kycAuth == 1)?_c('span',{staticClass:"tisg"},[_vm._v("您的认证审批中，请稍后重新查看")]):_vm._e(),(_vm.userInfo.kycAuth == 3)?_c('span',{staticClass:"tisg"},[_vm._v("您的认证未成功，请重新提交资料")]):_vm._e(),(_vm.userInfo.kycAuth == 2)?_c('span',{staticClass:"tisg"},[_vm._v("您已通过KYC认证，您的账户将受到更好的保护")]):_vm._e()]),_vm._m(9)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tops"},[_c('img',{attrs:{"src":"/static/image/safety.d3a323b5ad7cca95958707791f3861b1.png","alt":""}}),_c('div',{staticClass:"tes"},[_vm._v("完善账户信息，更安全")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lfs"},[_c('div',{staticClass:"topas"},[_vm._v("完善个人资料")]),_c('div',{staticClass:"tisg"},[_vm._v("资料更完整，我们的服务更加周到")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rigs"},[_vm._v(" 去完善 "),_c('img',{attrs:{"src":"/static/image/right.b9a9c7c64558347505384ad01922580c.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lfs"},[_c('div',{staticClass:"topas"},[_vm._v("卡片管理")]),_c('div',{staticClass:"tisg"},[_vm._v("如需提现，请绑定银行卡或虚拟币地址")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rigs"},[_c('img',{attrs:{"src":"/static/image/right.b9a9c7c64558347505384ad01922580c.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lfs"},[_c('div',{staticClass:"topas"},[_vm._v("登录密码管理")]),_c('div',{staticClass:"tisg"},[_vm._v("定期修改登录密码，有利账户安全")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rigs"},[_c('img',{attrs:{"src":"/static/image/right.b9a9c7c64558347505384ad01922580c.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lfs"},[_c('div',{staticClass:"topas"},[_vm._v("取款密码管理")]),_c('div',{staticClass:"tisg"},[_vm._v("定期修改登录密码，有利账户安全")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rigs"},[_c('img',{attrs:{"src":"/static/image/right.b9a9c7c64558347505384ad01922580c.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rigs"},[_c('img',{attrs:{"src":"/static/image/right.b9a9c7c64558347505384ad01922580c.png","alt":""}})])
}]

export { render, staticRenderFns }