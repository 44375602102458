<template>
  <div class="acts" style="background: white; min-height: 100vh;">
    <div class="pageTop">赞助</div>
    <div style="height: 30px;"></div>
    
    <div class="lis" @click="goToPage('/zhanzhuye?type=1')">
      <div class="lfs">
        <img src="/static/image/ddf471901f2b4fff9ee57015a1698227.png" />
        <div class="you">尤文图斯</div>
        <div class="te">官方区域合作伙伴</div>
      </div>
      <div class="rig">
        <img src="/static/image/93b000fa1d3246ce9b90a62c018714af.png" alt="" />
      </div>
    </div>

    <div class="lis" @click="goToPage('/zhanzhuye?type=2')">
      <div class="lfs">
        <img src="/static/image/ddf471901f2b4fff9ee57015a1698227.png" />
        <div class="you">阿斯顿维拉</div>
        <div class="te">官方全球顶级合作伙伴</div>
      </div>
      <div class="rig">
        <img src="/static/image/bd72c14c428d41ce8105a0d82a1bb696.png" alt="" />
      </div>
    </div>

    <van-divider :style="{ color: '#ccc', borderColor: '#ccc', padding: '20px 100px' }" dashed>
      没有更多了~
    </van-divider>
  </div>
</template>

<script>
export default {
  methods: {
    goToPage(path) {
      this.$parent.goNav(path);
    }
  }
};
</script>

<style scoped>
/* 在这里添加组件的样式 */
</style>
