<template>
  <div style="width: 100%; min-height: 100vh; background: white;">
    <!-- 顶部导航栏 -->
    <van-nav-bar title="卡片管理" left-arrow @click-left="$router.back()"
      style="position: fixed; top: 0; left: 0; width: 100%; background-color: #000" />

    <!-- 空白占位 -->
    <div style="height: 46px;"></div>

    <!-- 标签页 -->
    <van-tabs v-model="activeName">
      <van-tab :title="item.paymentMethodName" :name="item.paymentMethodName" v-for="(item, index) in withdrawList">
        <div v-for="(account, index) in item.withdrawAccount" :key="index" class="lis">
          <img class="lefs" src="/static/image/1595237922936176.png" alt="" />
          <div class="cest" v-if="item.paymentMethod == 300">
            <div class="type">{{ account.extend_0 }}</div>
            <div class="num">
              <span>****</span>
              <span>****</span>
              <span>****</span>
              <span>{{ account.extend_1.substr(-4) }}</span>
            </div>
          </div>
          <div class="cest" v-if="item.paymentMethod == 400">
            <div class="type">{{ account.extend_2 }} - {{ account.extend_0 }}</div>
            <div class="num">
              <span>****</span>
              <span>****</span>
              <span>****</span>
              <span>{{ account.extend_1.substr(-4) }}</span>
            </div>
          </div>
          <img class="rigss" src="/static/style/wdb_icon.png" alt=""
            @click="delCard(account.withdrawAccountId, item.currencyId, item.paymentMethod)" />
        </div>
        <div class="adds" v-if="item.paymentMethod == 300">
          <van-button v-if="usdssLis.length < 5" plain type="info" style="width: 80%;"
            @click="$parent.goNav('/addUsdtCard')">
            添加USDT地址
          </van-button>
          <div class="btntits">最多支持添加5个地址</div>
        </div>
        <div class="adds" v-if="item.paymentMethod == 400">
          <van-button v-if="usercardLis.length < 5" plain type="info" style="width: 80%;"
            @click="$parent.goNav('/addBankCard')">
            添加银行卡
          </van-button>
          <div class="btntits">最多支持添加5张银行卡</div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { addDeleteWithdrawAccount, getWithdrawData } from "@/service/billing";
import { refreshPlayerData } from '@/service/hall';
export default {
  data() {
    return {
      type: '1', // 默认显示第一个 Tab
      usdssLis: [], // 虚拟币卡片列表
      usercardLis: [], // 银行卡列表


      qutype: 0,
      amount: null,
      bankId: null,
      chanmeyXi: null,
      banklist: [],
      cardInfo: {},
      usdtInfo: {},
      password: null,
      bindShow: 0,
      activeName: null,
      withdrawList: [],
    };
  },
  computed: {
    userBanlance: function () {
      const cItem = this.$store.state.userInfo.cItem
      if (cItem && cItem.length > 0) {
        return cItem[0].value;
      }
      return 0;
    }
  },
  created() {
    const that = this;
    that.getWithdrawDataList();
    that.getConfitData();
  },
  methods: {
    getConfitData() {
      let that = this;
      that.configData = that.$parent.getConfigDatas();
      console.log('获取到配置信息为', that.configData);
      if (!that.configData || !that.configData.exchangeRateInfo) {
        setTimeout(() => {
          that.getConfitData();
        }, 500);
      } else {
        let exchangeRateInfo = that.configData.exchangeRateInfo
        that.exchangeRate = exchangeRateInfo.ExchangeRate || 0
        that.banklist = that.configData.bankList || []
        console.log('获取到配置信息为---汇率信息', that.banklist);
      }
    },
    async getWithdrawDataList() {
      const that = this
      const res = await getWithdrawData({
        msgID: 400303
      })
      if (res.error > 0) {
        this.showTost(0, res.error);
        return;
      }

      that.withdrawList = res.withdrawList
      if (res.withdrawList && res.withdrawList.length > 0 && !that.activeName) {
        that.activeName = res.withdrawList[0].paymentMethodName
      }

      that.networks = that.withdrawList.find((item) => item.paymentMethod == 300).network

      console.log('返回提现数据', res);
    },
    delCard(id, currencyId, paymentMethod) {
      let that = this;
      that.$dialog
        .confirm({
          title: '提示',
          message: '确定要解绑吗?',
        })
        .then(() => {
          that.doDeleteBank(id, currencyId, paymentMethod)
        })
        .catch(() => { });

    },
    async doDeleteBank(id, currencyId, paymentMethod) {
      const that = this
      const res = await addDeleteWithdrawAccount({
        msgID: 400309,
        currencyId: currencyId,
        paymentMethod: paymentMethod,
        type: 2,
        withdrawAccountId: id
      })
      if (res.error > 0) {
        that.$parent.showTost(0, res.error);
        this.hideLoading();
        return;
      }
      that.$parent.showTost(1, '解绑成功');
      that.getWithdrawDataList()
    },
    async getBalances() {
      let that = this;
      that.showLoading();
      try {
        let that = this;
        const res = await refreshPlayerData({
          msgID: 400033
        })
        if (res.error > 0) {
          that.showTost(0, res.error);
          return
        }
        localStorage.setItem('userInfo', JSON.stringify(res.playerInfo));
        that.$store.commit('changUserInfo');
        console.log('获得用户信息-->', res);
      } finally {
        that.hideLoading();
      }
    },

    retrun(event) {
      event.stopPropagation();
    },
    closeCv() {
      let that = this;
      that.openInfo = {};
      that.amount = null;
    },
    changApiType(e) {
      let that = this;
      if (that.qutype == 1) {
        let chanmeyXi = null;
        that.usdssLis.forEach(el => {
          if (el.id == e) {
            chanmeyXi = e.bank_owner;
            return;
          }
        });
        that.chanmeyXi = chanmeyXi;
      } else {
        that.chanmeyXi = null;
      }

      that.password = null;
      that.amount = null;
    },
    changBindShow(type) {
      let that = this;
      that.bindShow = type;
      that.cardInfo = {};
      that.usdtInfo = {};
    },
    async bindUsdss() {
      let that = this;
      let usdtInfo = that.usdtInfo;
      usdtInfo.bank = 'USDT';
      if (usdtInfo.bank_no == null || usdtInfo.bank_no == '') {
        that.$parent.showTost(0, '请输入USDT地址');
        return;
      }
      if (!usdtInfo.bank_owner || usdtInfo.bank_owner == null) {
        that.$parent.showTost(0, '请选择钱包协议');
        return;
      }
      // if (!usdtInfo.pay_pass) {
      //   that.$parent.showTost(0, '请输人支付密码');
      //   return;
      // }
      that.$parent.showLoading();
      const withdraw = that.withdrawList.find((item) => item.paymentMethod == 300)
      const res = await addDeleteWithdrawAccount({
        msgID: 400309,
        type: 1,
        currencyId: withdraw.currencyId,
        paymentMethod: withdraw.paymentMethod,
        network: usdtInfo.bank_owner,
        withdrawAccount: {
          extend_0: usdtInfo.bank_owner,
          extend_1: usdtInfo.bank_no,
        }
      })
      if (res.error > 0) {
        that.$parent.showTost(0, res.error);
        this.hideLoading();
        return;
      }
      console.log('请求添加提现账户返回', res);
      that.$parent.showTost(1, res.message);

      that.$parent.showTost(1, '绑定成功');
      that.getWithdrawDataList();
      that.changBindShow(0)

      // that.changBindShow(0);
      // that.getUsercard();
      // that.getUsdssList();
      this.hideLoading();
    },
    async bindCard() {
      let that = this;
      if (!that.cardInfo.bank_owner) {
        that.$parent.showTost(0, '请输入姓名');
        return;
      }
      if (!that.cardInfo.bank) {
        that.$parent.showTost(0, '请输入银行');
        return;
      }
      // if (!that.cardInfo.bank_address) {
      //   that.$parent.showTost(0, '请输入开户行地址');
      //   return;
      // }
      if (!that.cardInfo.bank_no) {
        that.$parent.showTost(0, '请输人银行卡号');
        return;
      }
      // if (!that.cardInfo.pay_pass) {
      //   that.$parent.showTost(0, '请输人支付密码');
      //   return;
      // }
      if (that.cardInfo.bank_no.length < 8) {
        that.$parent.showTost(0, '请输人正确的卡号长度');
        return;
      }
      // if (that.cardInfo.pay_pass.length < 6 || that.cardInfo.pay_pass.length > 18) {
      //   that.$parent.showTost(0, '请输人支付密码长度');
      //   return;
      // }
      try {
        that.showLoading();
        const withdraw = that.withdrawList.find((item) => item.paymentMethod == 400)
        const res = await addDeleteWithdrawAccount({
          msgID: 400309,
          currencyId: withdraw.currencyId,
          type: 1,
          paymentMethod: withdraw.paymentMethod,
          withdrawAccount: {
            extend_0: that.cardInfo.bank,
            extend_1: that.cardInfo.bank_no,
            extend_2: that.cardInfo.bank_owner
          }
        })
        if (res.error > 0) {
          that.$parent.showTost(0, res.error);
          this.hideLoading();
          return;
        }
        that.$parent.showTost(1, '绑定成功');
        that.getWithdrawDataList();
        that.changBindShow(0)
      } finally {
        that.hideLoading();
      }
    },

    goNav(url) {
      let that = this;
      that.$parent.goNav(url);
    },
    getUserInfo() {
      let that = this;
      that.$parent.getUserInfo();
    },
    showLoading() {
      this.$parent.showLoading();
    },
    hideLoading() {
      this.$parent.hideLoading();
    },
    openKefu() {
      this.$parent.openKefu();
    },
    showTost(type, title) {
      this.$parent.showTost(type, title);
    },
  },
};
</script>

<style scoped>
.lis {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.lefs {
  width: 40px;
  height: 40px;
}

.cest {
  flex: 1;
  margin-left: 10px;
}

.type {
  font-size: 14px;
  color: #333;
}

.num {
  font-size: 12px;
  color: #666;
}

.rigss {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.adds {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btntits {
  font-size: 12px;
  color: #999;
  text-align: center;
  margin-top: 5px;
}
.van-button--info {
  background-color: #dec562 !important;
  border: unset !important;
  color: #bd171b !important;
}
</style>
